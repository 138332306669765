import {useLocaleStore} from "~/stores/locale";

export default function ({
	isHMR,
	app,
	store,
	route,
	params,
	error,
	redirect,
}) {
	const localeStore = useLocaleStore()
	const defaultLocale = app.i18n.fallbackLocale
	// If middleware is called from hot module replacement, ignore it
	if (isHMR) return
	// Get locale from params
	const locale = params.lang || defaultLocale
	if (localeStore && !localeStore.locales.includes(locale)) {
		return error({ message: 'This page could not be found.', statusCode: 404 })
	}
	// Set locale

	app.i18n.locale = localeStore.locale || defaultLocale
}
